const groq = require('groq');

//
// === Drafts ===
//

module.exports.draftDocument = draftId => groq`*[_id == "${draftId}"][0] {...}`;

//
// ===   ===
//

module.exports.searchMeta = groq`{
	_type,
	description,
	keywords,
}`;

module.exports.link = groq`{
	_key,
	_type,
	openInNewWindow,
	title,
	url,
}`;

module.exports.pageLink = groq`{
	_key,
	_type,
	openInNewWindow,
	title,
	'pageName': page->title,
	'url': page->url,
}`;

module.exports.productLink = groq`{
	_key,
	_type,
	openInNewWindow,
	title,
	'productName': product->title,
	'slug': product->slug.current,
}`;

module.exports.collectionLink = groq`{
	_key,
	_type,
	openInNewWindow,
	title,
	'collectionName': collection->title,
	'slug': collection->slug.current,
}`;

module.exports.cta = groq`{
	...,
	_type == 'link' => ${module.exports.link},
	_type == 'pageLink' => ${module.exports.pageLink},
	_type == 'productLink' => ${module.exports.productLink},
	_type == 'collectionLink' => ${module.exports.collectionLink},
}`;

module.exports.asset = groq`{
	_type,
	_key,
	alt,
	'dimensions': asset->metadata.dimensions,
	'url': asset->url,
}`;

module.exports.youtube = groq`{
	_type,
	_key,
	url,
	oEmbed {
		title,
		height,
		thumbnail_height,
		width,
		thumbnail_width,
		author_name,
		author_url,
		thumbnail_url,
		provider_name,
		provider_url,
		html,
		type,
		version,
	},
}`;

module.exports.richText = groq`[]{
	...,
	"asset": asset->,

	markDefs[] {
		...,
		"image": image ${module.exports.asset},
	}
}`;

module.exports.localeRichText = groq`{
	...,
	es ${module.exports.richText},
	en ${module.exports.richText},
}`;

module.exports.seo = groq`{
	metaTitle,
	metaDescription,
	metaKeywords,
	openGraphTitle,
	openGraphDescription,
	openGraphImage ${module.exports.asset},
	twitterTitle,
	twitterDescription,
	twitterImage ${module.exports.asset},
}`;

module.exports.subscription = groq`{
	_id,
	_key,
	title,
	discountAmount,
	discountType,
	chargeIntervalFrequency,
	cutoffDayOfMonth,
	cutoffDayOfWeek,
	expireAfterSpecificNumberOfCharges,
	modifiableProperties[],
	numberChargesUntilExpiration,
	orderDayOfMonth,
	orderDayOfWeek,
	orderIntervalFrequencyOptions[],
	orderIntervalUnit,
	storefrontPurchaseOptions,
}`;

module.exports.productVariant = groq`{
	_id,
	variantId,
	productId,
	title,
	sku,
	cents,
	price,
	option1,
	option2,
	option3,
	mainImage ${module.exports.asset},
}`;

module.exports.productOption = groq`{
	_key,
	name,
	values[] {
		_key,
		name,
	}
}`;

module.exports.productTile = groq`{
	_key,
	_id,
	_type,
	title,
	'color': color.hex,
	'slug': slug.current,
	productId,
	'variantId': variants[0]->variantId,
	subscription->${module.exports.subscription},
	cents,
	compareAtCents,
	mainImage ${module.exports.asset},
	mainImageInverted ${module.exports.asset},
	bundleTileImage ${module.exports.asset},
	bundleCondensedImage ${module.exports.asset},
	bundleExpandedImage ${module.exports.asset},
	heroImages[] ${module.exports.asset},
	tags[],
	'ages': ages[]->_id,
	'symptoms': symptoms[]->_id,
	drugName,
	activeIngredients,
	description ${module.exports.localeRichText},

	variants[]-> ${module.exports.productVariant},
	options[] ${module.exports.productOption},

	searchMeta ${module.exports.searchMeta},
	hideLowInventoryProduct,
	link,
	link2,
	linkTitle1,
	linkTitle2,
	buyonamazon_link,
	findstore_link,
	upc,
}`;

module.exports.bundleTile = groq`{
	_key,
	_id,
	_type,
	title,
	products[]->${module.exports.productTile},
	description,
	discountBadge,
	cents,
	collectionTileTitle,
	mainImage ${module.exports.asset},
	mainImageLandscape ${module.exports.asset},
	searchMeta ${module.exports.searchMeta},
}`;

module.exports.form = groq`{
	'id': id.current,
	cta,

	fields [] {
		...,
		_type == "formInputText" => {
			required,
			name,
			placeholder
		},
		_type == "formInputTextArea" => {
			required,
			name,
			placeholder
		},
		_type == "formInputTel" => {
			required,
			name,
			placeholder
		},
		_type == "formInputEmail" => {
			required,
			name,
			placeholder
		},
		_type == "formInputSelect" => {
			required,
			name,
			placeholder,
			options[],
		},
		_type == "formTitle" => {
			title,
			blank,
		},
	}
}`;

module.exports.components = groq`components[] {
	...,

	_type == 'heroHome' => {
		_key,
		layout,
		title ${module.exports.localeRichText},
		description ${module.exports.localeRichText},
		desktopBackground ${module.exports.asset},
		mobileBackground ${module.exports.asset},
		'cta': cta[0] ${module.exports.cta},
		videoCta,
		backgroundVideo {
			...,
			asset->{
				...,
				"url": "https://stream.mux.com/" + playbackId
			}
		},
		modalVideo {
			...,
			asset->{
				...,
				"url": "https://stream.mux.com/" + playbackId
			}
		},
	},

	_type == 'heroCarousel' => {
		_key,
		slides[] {
			_key,
			_type,
			_type == 'heroHome' => {
				layout,
				title ${module.exports.localeRichText},
				description ${module.exports.localeRichText},
				desktopBackground ${module.exports.asset},
				mobileBackground ${module.exports.asset},
				'cta': cta[0] ${module.exports.cta},
				'ctaColor':ctaColor.hex,
				'ctaBackgroundColor':ctaBackgroundColor.hex
			},
			_type == 'videoSlide' => {
				layout,
				title ${module.exports.localeRichText},
				description ${module.exports.localeRichText},
				desktopBackground ${module.exports.asset},
				mobileBackground ${module.exports.asset},
				'cta': cta[0] ${module.exports.cta},
				videoCta,
				backgroundVideo {
					...,
					asset->{
						...,
						"url": "https://stream.mux.com/" + playbackId
					}
				},
				modalVideo {
					...,
					asset->{
						...,
						"url": "https://stream.mux.com/" + playbackId
					}
				},
			},
			_type == 'productSlide' => {	
				'slideBackground':slideBackground.hex,
				title${module.exports.localeRichText},
				description${module.exports.localeRichText},
				'cta': cta[0] ${module.exports.cta},
				'ctaColor':ctaColor.hex,
				'ctaBackgroundColor':ctaBackgroundColor.hex,
				extaInfo${module.exports.localeRichText},
				'extraInfoBackgroundColor':extraInfoBackgroundColor.hex,
				sideBgImage${module.exports.asset},
				sideBgImageMobile${module.exports.asset},
				familyImage1${module.exports.asset},
				familyImage2${module.exports.asset},
				familyImage3${module.exports.asset},
				familyImage1Mobile${module.exports.asset},
				familyImage2Mobile${module.exports.asset},
				familyImage3Mobile${module.exports.asset},
				product1${module.exports.asset},
				product2${module.exports.asset},
				product3${module.exports.asset},
				product1mobile${module.exports.asset},
				product2mobile${module.exports.asset},
				product3mobile${module.exports.asset},
				'product1cta': product1cta[0] ${module.exports.cta},
				'product2cta': product2cta[0] ${module.exports.cta},
				'product3cta':product3cta[0] ${module.exports.cta},
				
			},
		}
	},

	_type == 'heroPage' => {
		_key,
		title ${module.exports.localeRichText},
		titleWidth,
		subtitle ${module.exports.localeRichText},
		subtitleWidth,
		description ${module.exports.localeRichText},
		width,
		'cta': cta[0] ${module.exports.cta},
		'ctaColor': ctaColor.hex,
	},

	_type == 'heroPartner' => {
		_key,
		title ${module.exports.localeRichText},
		description ${module.exports.localeRichText},
		ctaList[] ${module.exports.cta},
	},

	_type == 'brandComparison' => {
		_key,
		ourbrandImage ${module.exports.asset},
		otherbrandImage ${module.exports.asset},
		ourBrandName ${module.exports.localeRichText},
		otherBrandName ${module.exports.localeRichText},
		ourProductPrice,
		otherProductPrice,
		ourProductPricePerOz,
		otherProductPricePerOz,
		'cta': cta[0] ${module.exports.cta},
		points[] {
			_key,
			ourpointcolor,
			ourpoint ${module.exports.localeRichText},
			otherpointcolor,
			otherpoint ${module.exports.localeRichText},
		},
	},


	_type == 'landingPageCompare' => {
		_key,
		title,
		sub_title,
		button_text,
		link,
		genexa_image ${module.exports.asset},
		other_brand_image ${module.exports.asset},
		genexa_image_mobile ${module.exports.asset},
		other_brand_image_mobile ${module.exports.asset},
	},

	_type == 'brandProductComparison' => {
		_key,
		ourBrandProductName ${module.exports.localeRichText},
		otherBrandProductName ${module.exports.localeRichText},
		'cta': cta[0] ${module.exports.cta},
		points[] {
			_key,
			ourpointcolor,
			ourpoint ${module.exports.localeRichText},
			otherpointcolor,
			otherpoint ${module.exports.localeRichText},
		},
	},

	_type == 'doctorsBioGrid' => {
		_key,
		title,
		description ${module.exports.localeRichText},
		'cta': cta[0] ${module.exports.cta},
		'ctaColor': ctaColor.hex,
		doctors[] {
			_key,
			title,
			description ${module.exports.localeRichText},
			image ${module.exports.asset},
		}
	},

	_type == 'duotoneBumper' => {
		_key,
		title,
		description ${module.exports.localeRichText},
		'cta': cta[0] ${module.exports.cta},
		'foregroundColor': foregroundColor.hex,
		'backgroundColor': backgroundColor.hex,
	},

	_type == 'ingredientOverview' => {
		_key,
		title,
		'cta': cta[0] ${module.exports.cta},
		ingredientSets[] {
			_key,
			title,
			description ${module.exports.localeRichText},
			ingredients[]{
				_key,
				title,
				'color': color.hex,
				image ${module.exports.asset},
				imageLayout,
			}
		}
	},

	_type == 'ingredientComparisonCarousel' => {
		_key,
		title,
		description ${module.exports.localeRichText},
		'cta': cta[0] ${module.exports.cta},
		'ctaColor': ctaColor.hex,
		 'ctaBackgroundColor':ctaBackgroundColor.hex,
		slides[] {
			_key,
			title,
			'color': color.hex,
			otherBrandBottleDesktop ${module.exports.asset},
			genexaBottleDesktop ${module.exports.asset},
			otherBrandBottleMobile ${module.exports.asset},
			genexaBottleMobile ${module.exports.asset},
			otherBrandDescription ${module.exports.localeRichText},
			genexaDescription ${module.exports.localeRichText},
			genexaProduct-> {
				productId,
				title,
				'slug': slug.current,
			},
			otherBrandIngredients[] {
				_key,
				title,
				active,
			},
			genexaIngredients[] {
				_key,
				title,
				active,
			},
		}
	},

	_type == 'imageCarousel' => {
		_key,
		title,
		items[] {
			title,
			description ${module.exports.localeRichText},
			image ${module.exports.asset},
		}
	},
	_type == 'socialMediaList' => {
		_key,
		title,
		items[] {
			name,
			social_media_links ,
			image ${module.exports.asset},
		}
	},

	
	_type == 'bannerWithCta' => {
		_key,
		desktopBackground ${module.exports.asset},
		mobileBackground ${module.exports.asset},
		'cta': cta[0] ${module.exports.cta},
		'ctaColor': ctaColor.hex,
		'ctaBackgroundColor': ctaBackgroundColor.hex,
		
	},
	_type == 'heroBannerHomePage' => {
		_key,
		desktopBackground ${module.exports.asset},
		mobileBackground ${module.exports.asset},
		link
	},
	
	_type == 'certificationsList' => {
		certificationGroups [] {
			_key,
			title ${module.exports.localeRichText},
			certifications[] {
				_key,
				logo ${module.exports.asset},
				title,
				description ${module.exports.localeRichText},
			},
		}
	},

	_type == 'partnerLogoGrid' => {
		_key,
		title ${module.exports.localeRichText},

		partners[] {
			_key,
			logo ${module.exports.asset},
			title,
			description ${module.exports.localeRichText},
		},
	},

	_type == 'certificationsGrid' => {
		_key,
		title ${module.exports.localeRichText},
		certifications[] {
			_key,
			logo ${module.exports.asset},
			description ${module.exports.localeRichText},
		},
		'cta': cta[0] ${module.exports.cta},
		'ctaColor': ctaColor.hex
	},

	_type == 'testimonial' => {
		'foregroundColor': foregroundColor.hex,
		'backgroundColor': backgroundColor.hex,
		images [] ${module.exports.asset},
		title,
		author,
		description ${module.exports.localeRichText},
		'cta': cta[0] ${module.exports.cta},
		layout,
		'ctaColor': ctaColor.hex,		
		'ctaBackgroundColor': ctaBackgroundColor.hex
	},

	_type == 'testimonialCarousel' => {
		'foregroundColor': foregroundColor.hex,
		'backgroundColor': backgroundColor.hex,
		slides[] {
			_key,
			image ${module.exports.asset},
			title,
			author,
			description ${module.exports.localeRichText},
			'cta': cta[0] ${module.exports.cta},
		}
	},

	_type == 'infoPage' => {
		sections[] {
			_key,
			title,
			copy ${module.exports.localeRichText},
		}
	},

	_type == 'faqPage' => {
		sections[] {
			_key,
			title,
			questions[] {
				_key,
				question,
				answer ${module.exports.localeRichText},
			}
		}
	},

	_type == 'richTextColumns' => {
		_key,
		columns[] ${module.exports.localeRichText},
	},

	_type == 'doctorsModalGrid' => {
		doctors[] {
			_key,
			name,
			description,
			biography ${module.exports.localeRichText},
			image ${module.exports.asset},
			url
		}
	},

	_type == 'pressTestimonials' => {
		_key,
		'foregroundColor': foregroundColor.hex,
		'backgroundColor': backgroundColor.hex,
		list [] {
			_key,
			title,
			description  ${module.exports.localeRichText},
			'image': image ${module.exports.asset},
		}
	},

	_type == 'pressGrid' => {
		_key,
		title,
		pressItems [] {
			_key,
			title,
			description  ${module.exports.localeRichText},
			logo ${module.exports.asset},
			url ${module.exports.link},
		}
	},

	_type == 'storeLocator' => {
		_key,
		title ${module.exports.localeRichText},
	},

	_type == 'fullWidthForm' => {
		_key,
		form-> ${module.exports.form},
	},

	_type == 'fullWidthKlaviyoForm' => {
		_key,
		form-> ${module.exports.form},
	},

	_type == 'productQuiz' => {
		_key,
		title,
		ageFilterSubtitle,
		ageFilterTitle,
		symptomFilterSubtitle,
		symptomFilterTitle,
		emptyState,
		'ctaColor': ctaColor.hex,
		'ctaBackgroundColor':ctaBackgroundColor.hex,
		ages[]-> {
			_key,
			_id,
			title,
			tileImage ${module.exports.asset},
			tileImageInvert ${module.exports.asset},
		},
		'symptoms': *[_type == "symptom"] {
			_key,
			_id,
			title,
			'color': symptomColor.hex,
		},
		'products': *[_type == "product" && !('Subscription' in tags) && !(disablePage == true) && !(deletedShopify == true)] ${module.exports.productTile}
	},

	_type == 'calloutCollection' => {
		_key,
		title,
		callouts[] {
			_key,
			image ${module.exports.asset},
			'cta': cta[0] ${module.exports.cta},
			'ctaColor':ctaColor.hex,
			'ctaBackgroundColor':ctaBackgroundColor.hex
		},

		_type == 'horizontalRule' => {
			_key,
			width,
		},
	},

	_type == 'mediaWithRichText' => {
		_key,
		title,
		subheadline,
		description ${module.exports.localeRichText},

		'media': media[0] {
			...,
			_type == 'image' => ${module.exports.asset},
			_type == 'youtube' => ${module.exports.youtube},
		},
	},

	_type == 'fullWidthMedia' => {
		_key,
		'media': media[0] {
			...,
			_type == 'image' => ${module.exports.asset},
			_type == 'youtube' => ${module.exports.youtube},
		},
	},

	_type == 'instagramGrid' => {
		_key,
		title,
		images[] ${module.exports.asset},
		'foregroundColor': foregroundColor.hex,
		'backgroundColor': backgroundColor.hex,
	},

	_type == 'hubspotForm' => {
		_key,
		title,
		CodeSnippet
	},

	_type == 'fullWidthRichText' => {
		_key,
		layout,
		width,
		description ${module.exports.localeRichText},
		textSize,
	},

	_type == 'fullWidthRichText' => {
		_key,
		width,
	},

	_type == 'productDetails' => {
		_key,
		symptomsTitle,
		ingredientsTitle,
	},

	_type == 'productCertifications' => {
		_key,
		'backgroundColor': backgroundColor.hex,
	},

	_type == 'productReviews' => {
		_key,
		title,
	},

	_type == 'curalateProductGallery' => {
		_key,
		width,
		description ${module.exports.localeRichText}
	},

	_type == 'relatedProducts' => {
		_key,
		title,
	},

	_type == 'freerelatedProducts' => {
		_key,
		title,
	},

	_type == 'productPress' => {
		_key,
		title,
		pressItems [] {
			_key,
			logo ${module.exports.asset},
			'cta': cta[0] ${module.exports.cta},
		}
	},

	_type == 'curalateHomeGallery' => {
		_key,
		width,
		description ${module.exports.localeRichText}
	},
	_type == 'ourStoryVideo' => {
		'backgroundColor': backgroundColor.hex,
		storyVideo {
				...,
				asset->{
					...,
					"url": "https://stream.mux.com/" + playbackId
				}
			},
		title,
	},
	_type == 'centerImageSideText' => {
		_key,
		lefttext ${module.exports.localeRichText},
		righttext ${module.exports.localeRichText},
		'leftBackgroundColor': leftBackgroundColor.hex,
		'rightBackgroundColor': rightBackgroundColor.hex,
		image ${module.exports.asset},
	},
	_type == 'textBanner' => {
		_key,
		text ${module.exports.localeRichText},
		image ${module.exports.asset},
	},
	_type == 'optionList' => {
		_key,
		name,
		optionlist[] ,
	},

	_type == 'celebsList' => {
		_key,
		title,
		items[] {
			celeb_name,
			description ${module.exports.localeRichText},
			image ${module.exports.asset},
		}
	},

	_type == 'centerText' => {
		_key,
		text ${module.exports.localeRichText},
		image ${module.exports.asset},
	},
	_type == 'sideBySideImageText' => {
		_key,
		text ${module.exports.localeRichText},
		image ${module.exports.asset},
    },
    _type == 'imagebyimage' => {
		_key,
		leftimage ${module.exports.asset},
		rightimage ${module.exports.asset},
		'leftimagelink': leftimagelink[0] ${module.exports.cta},
		'rightimagelink': rightimagelink[0] ${module.exports.cta},
		heading ${module.exports.localeRichText},
		subheading ${module.exports.localeRichText},
		'cta': cta[0] ${module.exports.cta},
		'ctaColor':ctaColor.hex,
		'ctaBackgroundColor':ctaBackgroundColor.hex,
		'leftcta': leftcta[0] ${module.exports.cta},
		'leftctaColor':leftctaColor.hex,
		'leftctaBackgroundColor':leftctaBackgroundColor.hex,
		'rightcta': rightcta[0] ${module.exports.cta},
		'rightctaColor':rightctaColor.hex,
		'rightctaBackgroundColor':rightctaBackgroundColor.hex,
    },
	_type == 'infoText' => {
		_key,
		text ${module.exports.localeRichText},
		'backgroundColor': backgroundColor.hex,
	},
	_type == 'heroJoinTheMovement' => {
		_key,
		title ${module.exports.localeRichText},
		subtitle ${module.exports.localeRichText},
		'color':color.hex,
		'backgroundColor':backgroundColor.hex,
		'cta': cta[0] ${module.exports.cta},
		'ctaColor':ctaColor.hex,
		'ctaBackgroundColor':ctaBackgroundColor.hex,
	},
	_type == 'heroPageIgaf' => {
		_key,
		title ${module.exports.localeRichText},
		subtitle ${module.exports.localeRichText},
		'cta': cta[0] ${module.exports.cta},
		'ctaColor':ctaColor.hex,
		'ctaBackgroundColor':ctaBackgroundColor.hex
	},
	_type == 'findGenexa' => {
		_key,
		heading ${module.exports.localeRichText},
		partners[] {
			_key,
			 image ${module.exports.asset},
			'cta': cta[0] ${module.exports.cta},
		},
		'cta': cta[0] ${module.exports.cta},
		'ctaColor': ctaColor.hex,
		'ctaBackgroundColor': ctaBackgroundColor.hex
	},
	_type == 'igafCertifications' => {
		_key,
		certifications[] {
			_key,
			 image ${module.exports.asset},
		},
		'backgroundColor': backgroundColor.hex
	},
	_type == 'productsCarousel' => {
		_key,
		products[]->${module.exports.productTile}
	},
	_type == 'productsCarouselWithArrow' => {
		_key,
		title,
		products[] {
			name,
			shot_description,
			link,
			image ${module.exports.asset},
		}
	},
	_type == 'canadianLandingPage' => {
		_key,
		headerImage ${module.exports.asset},
		title ${module.exports.localeRichText},
		medicineFor,
		medicineDetails ${module.exports.localeRichText},
		reliefFromList[] {
			_key,
			reliefFrom,
		},
		ingredientsHeaderText ${module.exports.localeRichText},
		ingredients[] {
			_key,
			 ingredient,
		},
		ingredientsFooterText ${module.exports.localeRichText},
		ingredientsFooterInfo ${module.exports.localeRichText},
		cleanMedicineHeading,
		otherDetail ${module.exports.localeRichText},
		otherProductDetail[] {
			_key,
			 details,
		},
		certifications[] {
			_key,
			 certificationImage ${module.exports.asset},
		},
		otherText ${module.exports.localeRichText},
	    drugInfo[] {
			_key,
			facts ${module.exports.localeRichText},
			bottomBorderWidth,
			bottomBorderColor,
			dosageTable[]
		},
	},

	_type == 'patentTable' => {
		    _key,
			title ${module.exports.localeRichText},
			description ${module.exports.localeRichText},
			imageDesktop ${module.exports.asset},
			imageMobile ${module.exports.asset},
		    patentTableList[]  {
				_key,
				link ${module.exports.link},
				productskus,
				uspatent,
			}, 
	                                       
	       },
	_type == 'adsSectionBlog' => {
		    _key,
			blogAds->{
				_key,
				_id,
				title ${module.exports.localeRichText},
				description ${module.exports.localeRichText},
				desktopAdsImage ${module.exports.asset},
				mobileAdsImage ${module.exports.asset},
				'adsBackgroudColor': adsBackgroudColor.hex,
				'cta': cta[0] ${module.exports.cta},
				'ctaColor': ctaColor.hex,
				'ctaBackgroundColor': ctaBackgroundColor.hex
				}     
	       },
}`;

module.exports.blogAds = groq`{
	_key,
	_id,
	title ${module.exports.localeRichText},
	description ${module.exports.localeRichText},
	desktopAdsImage ${module.exports.asset},
	mobileAdsImage ${module.exports.asset},
	'adsBackgroudColor':adsBackgroudColor.hex,
	'cta': cta[0] ${module.exports.cta},
	'ctaColor':ctaColor.hex,
	'ctaBackgroundColor':ctaBackgroundColor.hex,
	}`;
	
module.exports.blogCategory = groq`{
	_key,
	_id,
	title,
	'color': blogCategoryColor.hex,
}`;


module.exports.blogAuthor = groq`{
	_key,
	_id,
	name,
	'slug': slug.current,
	role,
	link ${module.exports.link},
	image ${module.exports.asset},
	bio ${module.exports.localeRichText},
}`;

module.exports.blogCard = groq`{
	_key,
	_id,
	title,
	desktopBlogImage ${module.exports.asset},
	mobileBlogImage ${module.exports.asset},
	postDate,
	'slug': slug.current,
	category->${module.exports.blogCategory},
}`;

//
// === Globals ===
//

module.exports.header = groq`*[_type == "header"][0]{
	enableBanner,
	banner ${module.exports.localeRichText},
	enablePopup,
	popup ${module.exports.localeRichText},

	desktopMenu[] {
		...,
		_type == 'link' => ${module.exports.link},
		_type == 'pageLink' => ${module.exports.pageLink},
		_type == 'productLink' => ${module.exports.productLink},
		_type == 'collectionLink' => ${module.exports.collectionLink},
		_type == 'shopMenuTrigger' => {
			_key,
			title,
		}
	},

	shopMenuRows[] {
		_key,
		menu[] ${module.exports.cta},
	},

	shopMenuTiles[] {
		_key,
		'cta': cta[0] ${module.exports.cta},
		image ${module.exports.asset},
	},

	searchPlaceholder,
	takeoverLinks[] {
		_key,
		...,
		_type == 'link' => ${module.exports.link},
		_type == 'pageLink' => ${module.exports.pageLink},
		_type == 'productLink' => ${module.exports.productLink},
		_type == 'collectionLink' => ${module.exports.collectionLink},
		_type == 'childRow' => {
			_key,
			menu[] {
				...,
				_type == 'link' => ${module.exports.link},
				_type == 'pageLink' => ${module.exports.pageLink},
				_type == 'productLink' => ${module.exports.productLink},
				_type == 'collectionLink' => ${module.exports.collectionLink},
				_type == 'grandChildRow' => {
					_key,
					menu[] ${module.exports.cta},
				}
			},
		}
	},

	contactTitle,
	contactDescription ${module.exports.localeRichText},
}`;

module.exports.footer = groq`*[_type == "footer"][0]{
	desktopMenu {
		columns[] {
			_key,
			title,
			links[] ${module.exports.cta},
		},
		certifications{
			_key,
			title,
			images[] ${module.exports.asset},
		},
	},
	mobileMenu {
		columns[] {
			_key,
			title,
			links[] ${module.exports.cta},
		},
		socialLinks {
			_key,
			title,
			links[] {
				...,
				link ${module.exports.link},
				image ${module.exports.asset},
			},
		},
		infoLinks ${module.exports.localeRichText},
	},
	legal {
		title,
		description ${module.exports.localeRichText},
	},
	newsletter {
		_key,
		title,
		privacyAgreement ${module.exports.localeRichText},
		placeholder,
		cta,
		successMessage
	}
}`;

module.exports.cookiesDisclaimer = groq`*[_type == "cookiesDisclaimer"][0] {
	enableDisclaimer,
	disclaimer ${module.exports.localeRichText},
}`;

module.exports.account = groq`*[_type == "account"][0]{
	cancelationReasons[],
}`;

module.exports.redirects = groq`*[_type == 'redirect']{
	fromPath,
	toPath,
	statusCode
}`;

module.exports.passwordConfig = groq`*[_type == "passwordProtection"][0]{
	enablePassword,
	password,
}`;

module.exports.cartOption = groq`*[_type == "cart"][0]{
	freeproductOptions[]-> ${module.exports.productTile},
}`;

//
// === Homepage ===
//

module.exports.homepage = groq`*[_type == "homepage"][0] {
	hideFromSearchEngine,
	${module.exports.components},
	seo ${module.exports.seo},
}`;

module.exports.draftHomepage = draftId => groq`*[_type == "homepage" && _id == "${draftId}"][0] {
	hideFromSearchEngine,
	${module.exports.components},
	seo ${module.exports.seo},
}`;

//
// === Pages ===
//

module.exports.homeComponents = groq`*[_type == "homepage"][0] {
	${module.exports.components},
}`;

module.exports.pages = groq`*[_type == "page"] {
	hideFromSearchEngine,
	isLandingPage,
	customStatusMessage,
	customUtmRedirect,
	customModalContent,
	_key,
	_id,
	title,
	url,
	searchMeta ${module.exports.searchMeta},
	seo ${module.exports.seo},
	${module.exports.components},
}`;

module.exports.compareProduct = groq`*[_type == "compareProduct"  && !(_id in path("drafts.**")) && (hideFromWebsite!=true)] {
	hideFromSearchEngine,
	_key,
	_id,
	title,
	'slug': slug.current,
	description ${module.exports.localeRichText},
	searchMeta ${module.exports.searchMeta},
	seo ${module.exports.seo},
	productCategory->{
		title,
		'slug': slug.current,
	},
	productBrand->{
		title,
		'slug': slug.current,
		warning ${module.exports.localeRichText},
		},
	${module.exports.components},
}`;

module.exports.compareProductBrand = groq`*[_type == "compareProductBrand"  && !(_id in path("drafts.**")) && (hideFromWebsite!=true)] {
	hideFromSearchEngine,
	_key,
	_id,
	 title,
	'slug': slug.current,
	warning ${module.exports.localeRichText},
	searchMeta ${module.exports.searchMeta},
	seo ${module.exports.seo},
	"compare_pages": *[_type == "compareProduct" && references(^._id)   && !(_id in path("drafts.**")) && (hideFromWebsite!=true)]{
		_id,
	   _type,
	   title,
	  'slug': slug.current,
	  components[]{
		_type == 'brandComparison' => {
			_key,
			otherbrandImage ${module.exports.asset}
		 }

	    }
	 }
}`;
module.exports.compareProductCategory = groq`*[_type == "compareProductCategory"  && !(_id in path("drafts.**")) && (hideFromWebsite!=true)] {
	hideFromSearchEngine,
	_key,
	_id,
	 title,
	'slug': slug.current,
	warning ${module.exports.localeRichText},
	searchMeta ${module.exports.searchMeta},
	seo ${module.exports.seo},
	"compare_pages": *[_type == "compareProduct" && references(^._id)   && !(_id in path("drafts.**")) && (hideFromWebsite!=true)]{
		_id,
	   _type,
	   title,
	  'slug': slug.current,
	   components[]{
		_type == 'brandComparison' => {
			_key,
			otherbrandImage ${module.exports.asset}
		 }

	    }

	 }
}`;

module.exports.compareProductPageList = groq`*[_type == "compareProduct" && !(_id in path("drafts.**")) && (hideFromWebsite!=true)] {
	_key,
	_id,
	'id':_id,
	'name':title,
	'url':'/compare/'+slug.current,
	'brand':productBrand->title,
	'hideFromWebsite':productBrand->hideFromWebsite,
	'brand_url':'/compare/brand/'+productBrand->slug.current,
}`;

module.exports.draftPage = draftId => groq`*[_type == "page" && _id == "${draftId}"][0]{
	hideFromSearchEngine,
	_key,
	_id,
	title,
	url,
	searchMeta ${module.exports.searchMeta},
	seo ${module.exports.seo},
	${module.exports.components},
}`;

//
// === Collections ===
//
const collection = groq`
	hideFromSearchEngine,
	topBannerImage ${module.exports.asset},
	title,
	'slug': slug.current,
	tiles[]-> {
		_key,
		_type,
		_type == 'product' => ${module.exports.productTile},
		_type == 'bundle' => ${module.exports.bundleTile},
	},

	'allFilters': tiles[]-> {
		_key,
		_type,

		_type == 'product' => {
			symptoms[]-> {
				_key,
				_id,
				title,
			},
			ages[]-> {
				_key,
				_id,
				title,
			},
		},

		_type == 'bundle' => {
			products[]-> {
				symptoms[]-> {
					_key,
					_id,
					title,
				},
				ages[]-> {
					_key,
					_id,
					title,
				},
			}
		},
	},

	${module.exports.components},
	hideLowInventoryProduct,
	link,
	link2,
	linkTitle1,
	linkTitle2,
	searchMeta ${module.exports.searchMeta},
	seo ${module.exports.seo},
`;

module.exports.collections = groq`*[_type == "collection"] {
	${collection}
}`;

module.exports.draftCollection = draftId => groq`*[_type == "collection" && _id == "${draftId}"][0]{
	${collection}
}`;

//
// === Products ===
//

const product = groq`
	hideFromSearchEngine,
	_id,
	_key,
	productId,
	'variantId': variants[0]->variantId,
	title,
	'color': color.hex,
	'slug': slug.current,
	cents,
	tags,
	'sku': variants[0]->sku,
	'variantTitle': variants[0]->title,
	mainImage ${module.exports.asset},
	mainImageInverted ${module.exports.asset},
	heroImages[] ${module.exports.asset},
	drugName,
	activeIngredients,
	description ${module.exports.localeRichText},
	productDetails ${module.exports.localeRichText},

	ages[]-> {
		_key,
		title,
	},
	symptoms[]-> {
		_key,
		title,
		'color': symptomColor.hex,
	},
	ingredients[]-> {
		_key,
		title,
		'color': ingredientColor.hex,
	},
	certifications[] {
		_key,
		logo ${module.exports.asset},
	},

	${module.exports.components},

	subscription->${module.exports.subscription},
	relatedProducts[]->${module.exports.productTile},
	freerelatedProducts[]->${module.exports.productTile},

	options[] ${module.exports.productOption},
	variants[]-> ${module.exports.productVariant},

	searchMeta ${module.exports.searchMeta},
	seo ${module.exports.seo},
	hideLowInventoryProduct,
	link,
	link2,
	linkTitle1,
	linkTitle2,
	amazon_link,
	wallmart_link,
	findstore_link,
	buyonamazon_link,
	upc,
	useLayout2,
	enableBuyWithPrime,
	enableSquareProductImages,
	promo_offer ${module.exports.asset},
`;

// Ignore disabled pages
// Ignore subs products
module.exports.products = groq`*[_type == "product" && !('Subscription' in tags) && !(disablePage == true) && !(deletedShopify == true)]{
	${product}
}`;

module.exports.draftProduct = draftId => groq`*[_type == "product" && _id == "${draftId}" && !('Subscription' in tags) && !(disablePage == true) && !(deletedShopify == true)][0]{
	${product}
}`;

//
// === Search ===
//
module.exports.searchResults = groq`*[
	(
		_type == "product" &&
		!('Subscription' in tags) &&
		!(disablePage == true) &&
		!(deletedShopify == true)
	) ||
	_type == "bundle" ||
	_type == "page" ||
	_type == "collection"
] {
	_type == 'product' => ${module.exports.productTile},

	_type == 'bundle' => ${module.exports.bundleTile},

	_type == 'page' => {
		_key,
		_id,
		_type,
		title,
		url,
		searchMeta ${module.exports.searchMeta},
	},
}`;

// 404
module.exports.notFound = groq`*[_type == 'notFound'][0] {
	title ${module.exports.localeRichText},
	'cta': cta[0] ${module.exports.cta},
}`;

//
// === Bundle Builder ===
//

module.exports.bundleBuilder = groq`*[_type == 'bundleBuilder'][0] {
	title,

	modal {
		image ${module.exports.asset},
		title,
		description,
		closeCta,
	},

	prefillModal {
		image ${module.exports.asset},
		title,
		description,
	},

	productOptions[]-> {
		_key,
		_id,
		title,
		'slug': slug.current,
		productId,
		'variantId': variants[0]->variantId,
		subscription->${module.exports.subscription},
		cents,
		compareAtCents,
		bundleTileImage ${module.exports.asset},
		bundleCondensedImage ${module.exports.asset},
		bundleExpandedImage ${module.exports.asset},
		heroImages[] ${module.exports.asset},
		tags,
		'ages': ages[]->_id,
		'symptoms': symptoms[]->_id,
		drugName,
		activeIngredients,
		hideLowInventoryProduct,
		link,
		link2,
		linkTitle1,
		linkTitle2,
		description ${module.exports.localeRichText},
	},

	ageFilters[]-> {
		_id,
		_key,
		title,
	},

	'symptomFilters': *[_type == "symptom"] {
		_key,
		_id,
		title,
		'color': symptomColor.hex
	},

	freeproductOptions[]-> ${module.exports.productTile},
}`;

module.exports.cart = groq`*[_type == 'cart'][0] {
	freeShippingMinimumCents,
	bulkDiscountMinimumItems,
	bulkDiscountPercentage,
	emptyState ${module.exports.localeRichText},
	medicineCabinetTitle ${module.exports.localeRichText},
	medicineCabinetDescription ${module.exports.localeRichText},
	medicineCabinetCta,
	relatedProductsTitle,
	customerDiscountTags[],
	customerDiscountBanner ${module.exports.localeRichText},
	freeproductOptions[]-> ${module.exports.productTile},
}`;

module.exports.productTemplate = groq`*[_type == 'productTemplate'][0] {
	medicineCabinetTitle ${module.exports.localeRichText},
	medicineCabinetDescription ${module.exports.localeRichText},
	medicineCabinetCta,
	guaranteeDetails ${module.exports.localeRichText},
}`;

module.exports.collectionTemplate = groq`*[_type == 'collectionTemplate'][0] {
	collectionNav[]->{
		_type,
		title,
		'slug': slug.current,
	}
}`;

module.exports.zendesk = groq`*[_type == 'zendesk'][0] {
	routeConfig[] {
		_key,
		pathName,
		disable,
		delay,
	},
}`;

//
// === Blog Posts ===
//

module.exports.blogPosts = groq`*[_type == "blogPost" && !(_id in path("drafts.**"))] {
	hideFromSearchEngine,
	_key,
	_id,
	title,
	postDate,
	desktopBlogImage ${module.exports.asset},
	mobileBlogImage ${module.exports.asset},
	sources,
	'slug': slug.current,
	category->${module.exports.blogCategory},
	author->${module.exports.blogAuthor},
	relatedBlogPosts[]->${module.exports.blogCard},
	searchMeta ${module.exports.searchMeta},
	seo ${module.exports.seo},
	${module.exports.components},
}`;

module.exports.draftBlogPost = draftId => groq`*[_type == "blogPost" && _id == "${draftId}"][0] {
	hideFromSearchEngine,
	_key,
	_id,
	title,
	postDate,
	desktopBlogImage ${module.exports.asset},
	mobileBlogImage ${module.exports.asset},
	sources,
	'slug': slug.current,
	category->${module.exports.blogCategory},
	author->${module.exports.blogAuthor},
	relatedBlogPosts[]->${module.exports.blogCard},
	searchMeta ${module.exports.searchMeta},
	seo ${module.exports.seo},
	${module.exports.components},
}`;

module.exports.blogLatestPosts = groq`*[_type == "blogPost" && !(_id in path("drafts.**"))][0...21] | order(postDate) {
	_key,
	_id,
	title,
	desktopBlogImage ${module.exports.asset},
	mobileBlogImage ${module.exports.asset},
	postDate,
	'slug': slug.current,
	category->${module.exports.blogCategory},
}`;

//
// === Blog Authors ===
//

module.exports.blogAuthors = groq`*[_type == "blogAuthor"] {
	hideFromSearchEngine,
	_key,
	_id,
	name,
	'slug': slug.current,
	role,
	link ${module.exports.link},
	image ${module.exports.asset},
	bio ${module.exports.localeRichText},
	searchMeta ${module.exports.searchMeta},
	seo ${module.exports.seo},
}`;

module.exports.draftBlogAuthors = draftId => groq`*[_type == "blogAuthor" && _id == "${draftId}"][0] {
	hideFromSearchEngine,
	_key,
	_id,
	name,
	'slug': slug.current,
	role,
	link ${module.exports.link},
	image ${module.exports.asset},
	bio ${module.exports.localeRichText},
	searchMeta ${module.exports.searchMeta},
	seo ${module.exports.seo},
}`;

module.exports.blogCategoryWithPosts = groq`*[_type == "blogCategory"] {
	_key,
	_id,
	'id':_id,
	title,
	'color': blogCategoryColor.hex,
	"blog_posts": *[_type == "blogPost" && references(^._id) && !(_id in path("drafts.**"))]{
		_id,
	   _type,
	   title,
		desktopBlogImage ${module.exports.asset},
		mobileBlogImage ${module.exports.asset},
	   postDate,
	  'slug': slug.current,
	  author->{
			name,
			'slug': slug.current,
	  },
	   ${module.exports.components},
	 }
}`;
module.exports.blogLatestPostList = groq`*[_type == "blogPost" && !(_id in path("drafts.**"))] | order(postDate desc) {
	_key,
	_id,
	title,
	desktopBlogImage ${module.exports.asset},
	mobileBlogImage ${module.exports.asset},
	postDate,
	'slug': slug.current,
	category->${module.exports.blogCategory},
	components[ _type == "heroBlog"] {
		_key,
		_type,
		title,
		subtitle
	}
}`;